import { createSSRApp, createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './main.css';

const isSSR = typeof window === 'undefined';

export default function build() {
   const app = (isSSR ? createSSRApp(App) : createApp(App));

    app.use(router);

    return { app, router };
}