import {
    createRouter,
    createMemoryHistory,
    createWebHistory,
} from "vue-router";

const isServer = typeof window === "undefined";
const history = isServer ? createMemoryHistory() : createWebHistory();

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("./pages/home/Index.vue"),
    },
    {
        path: "/about",
        name: "about",
        component: () => import("./pages/about/Index.vue"),
    },
    {
        path: "/contact",
        name: "contact",
        component: () => import("./pages/contact/Index.vue"),
    },

    // {
    //     path: '/blog',
    //     name: 'blog',
    //     component: () => import("./pages/blog/Index.vue"),
    // },

    {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: () => import("./pages/Policy.vue"),
    },

     {
        path: "/terms-of-use",
        name: "terms-of-use",
        component: () => import("./pages/Terms.vue"),
    },

    {
        path: "/blog",
        redirect: "/",
    },

    //  {
    //     path: '/blog/Technological-Efficiency',
    //     name: 'Technological Efficiency',
    //     component: () => import("./pages/blog/technologicalEfficiency/Index.vue"),
    // },
    { path: "/:catchAll(.*)", redirect: "/" },
];

const router = createRouter({
    history,
    routes,
});

export default router;
