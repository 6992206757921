<template>
    <router-view />
</template>

<style></style>

<script>
export default {
    mounted() {
        // const el = document.getElementById("loader-container");
        // el.classList.add("hidden");
    },
};
</script>
